<template>
  <v-card>
    <validation-observer ref="obs" v-slot="{ errors, invalid, validated }">
      <v-toolbar color="titleBar" dark flat dense>
        <v-toolbar-title>
          <v-icon class="mr-4">mdi-human-greeting</v-icon>
          {{ $t("welcome to the {title}", { title: $config.title }) }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="d-flex justify-center ma-4">
        <p class="subtitle-1">
          <span>
            {{ $t("msg") }}
          </span>
          <br />
          <span>
            {{ $t("msg2") }}
          </span>
          <br />
          <span>
            {{ $t("msg3") }}
          </span>
        </p>
      </v-card-text>
      <v-card-text class="d-flex justify-center">
        <validation-provider
          vid="customer"
          :name="$t('customer number')"
          rules="required"
          v-slot="{ errors, valid, dirty, classes }"
        >
          <v-text-field
            v-model="customer"
            name="customer"
            :label="$t('customer number')"
            :error-messages="errors"
            :success="valid"
            :clearable="!isMobile"
            type="text"
            required
          />
        </validation-provider>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="loading || invalid"
          @click="resetPassword"
        >
          {{ $t("set password") }}
        </v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>

<script>
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "Welcome",
  props: {
    login: {
      type: String,
      required: false,
      default: null,
    },
  },
  mixins: [showErrors, isMobile],
  data: () => ({
    loading: false,
    customer: 0,
    twoFactorEnabled: false,
  }),
  watch: {
    login: {
      immediate: true,
      handler(value) {
        this.customer = value;
      },
    },
  },
  methods: {
    resetPassword() {
      var that = this;
      this.loading = true;
      this.$http
        .post("login/reset_password", { customer: this.customer })
        .then(function (response) {
          that.$snotify.confirm(
            that.$i18n.t("{response}. Please check your email inbox.", {
              response: response.data.detail[0],
            }),
            {
              showProgressBar: false,
              closeOnClick: false,
              buttons: [
                {
                  text: "OK",
                  action: (toast) => {
                    that.$snotify.remove(toast.id);
                  },
                  bold: true,
                },
              ],
            }
          );
          that.$router.push({ name: "login" });
        })
        .catch(function (error) {
          if (error.unauthorized()) {
            // 401: means 2FA is enabled and token required
            that.twoFactorEnabled = true;
          } else {
            that.$refs.obs.setErrors(error.data);
            that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
};
</script>

<i18n>
  {
    "en": {
      "welcome to the {title}": "Welcome to the {title}",
      "msg": "On our customer portal you can manage your subscriptions, bills and services as well as your customer settings.",
      "msg2": "You can log in with your customer number and your password. When you log in for the first time, you must first set your password. To do this, enter your customer number and click on 'Set password'.",
      "msg3": "We will then send you an email with a link where you can enter your new password.",
      "set password": "Set password",
      "customer number": "Customer number",
      "{response}. Please check your email inbox.": "{response}. Please check your email inbox."
    },
    "de": {
      "welcome to the {title}": "Willkommen auf dem {title}",
      "msg": "Auf unserem Kundenportal können Sie Ihre Abonnements, Rechnungen und Dienste sowie Ihre Kundeneinstellungen verwalten.",
      "msg2": "Einloggen können Sie sich mit Ihrer Kundennummer und Ihrem Passwort. Wenn Sie sich das erste mal anmelden, müssen Sie zuerst Ihr Passwort setzen. Geben Sie dazu Ihre Kundennummer ein und klicken auf 'Passwort setzen'.",
      "msg3": "Im Anschluss senden wir Ihnen eine Email mit einem Link, unter dem Sie Ihr neues Passwort eingeben können.",
      "set password": "Passwort setzen",
      "customer number": "Kundennummer",
      "{response}. Please check your email inbox.": "{response}. Bitte kontrollieren Sie Ihren Posteingang."
    }
  }
  </i18n>
